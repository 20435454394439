<template>
  <div style="background:#fff;">
    <div class="remind flex flex-center">
      <div>二维码名称</div>
      <input type="text" placeholder="请填写" v-model="title" />
    </div>
    <div class="remind flex flex-center">
      <div>选择群</div>
      <div class="flex flex-center">
        <div v-if="select.length == 0">请选择需要发送的群</div>
        <div>{{ name.join("、") }}</div>
        <!-- <div>7:00</div> -->
        <!-- <img class="remind-icon" src="../assets/imgs/arrow.png" alt="" /> -->
      </div>
    </div>
    <div class="remind-check flex wrap">
      <div
        class="remind-check_item"
        @click="selectRemind(item, index)"
        v-for="(item, index) in label"
        :class="item.select ? 'remind-check_selectItem' : ''"
        :key="index"
      >
        {{ item.name }}
      </div>
    </div>
    <!-- <div class="remind flex flex-center">
      <div>给此二维码打标签</div>
      <div cass="flex flex-center">
        <div>7:00</div>
        <img class="remind-icon" src="../assets/imgs/arrow.png" alt="" />
      </div>
    </div> -->
    <!-- <div class="remind-textarea">
      <div class="remindText-title">欢迎语</div>
      <textarea placeholder="请填写欢迎语"></textarea>
    </div> -->
    <!-- <div class="rulesView">
      <div class="rulesView-title">二维码头像</div>
      <img class="rulesView-add" src="../assets/imgs/add.png" alt="" />
    </div> -->

    <!-- 底部固定 -->
    <div class="bottom fixed flex">
      <div class="bottom-btn flex-item_center" @click="goBack()">取消</div>
      <div class="bottom-btn_save flex-item_center" @click="save()">保存</div>
    </div>
  </div>
</template>

<script>
import * as request from "../api/data";
import _ from "lodash";
import { Toast } from "vant";

export default {
  data() {
    return {
      label: [],
      select: [],
      name: [],
      bttonDisable: true,
      title: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async save() {
      let that = this;
      if (this.title == "") {
        Toast("请填写二维码名称");
        return false;
      }

      if (this.select.length == 0) {
        Toast("请选择群列表");
        return false;
      }
      if (this.bttonDisable) {
        this.bttonDisable = false;
        let res = await request._addQrcode({
          userid: localStorage.getItem("userid"),
          title: this.title,
          chat_id_list: this.select.join(","),
          state: "",
        });
        console.log("创建二维码 res", res);
        if (_.toInteger(res.code) === 1) {
          Toast.success("添加成功");
          this.bttonDisable = true;
          setTimeout(() => {
            that.$router.back();
          }, 1200);
        } else {
          this.bttonDisable = true;
        }
      }
    },
    goBack() {
      this.$router.back();
    },
    selectRemind(item, index) {
      if (this.select.length < 5) {
        this.label[index].select = !this.label[index].select;
        this.select = [];
        this.name = [];
        this.label.forEach((item) => {
          if (item.select) {
            this.select.push(item.chat_id);
            this.name.push(item.name);
          }
        });
      } else {
        Toast("最多选择5个群哦~");
      }
    },
    async getList() {
      let res = await request._groupList({
        userid: localStorage.getItem("userid"),
      });
      console.log("我的群列表 res", res);
      if (_.toInteger(res.code) === 1) {
        let arr = res.data;
        arr.forEach((item) => {
          item.select = false;
        });
        this.label = arr;
      }
    },
  },
};
</script>

<style scoped>
.remind {
  margin: 0 auto;
  width: 670px;
  background: #fff;
  border-bottom: 1px solid #ededf7;
  padding: 64px 0 26px 0;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  justify-content: space-between;
  color: #66728f;
}

.remind input {
  outline: none;
  border: 0;
  text-align: right;
}

.remind-icon {
  width: 12px;
  height: 20px;
  margin-left: 10px;
}

.remind-textarea {
  margin: 0 auto;
  width: 670px;
  background: #fff;
  border-bottom: 1px solid #ededf7;
  padding: 64px 0 26px 0;
}

.remindText-title {
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #66728f;
}

textarea {
  outline: none;
  border: 0;
  width: 670px;
  margin-top: 38px;
  height: 110px;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #101f48;
}

.rulesView {
  width: 670px;
  padding: 52px 0 88px 0;
  margin: 0 auto;
}

.rulesView-title {
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #66728f;
  margin: 0 0 37px 0;
}

.rulesView-add {
  width: 180px;
  height: 180px;
  background: #ededf7;
  border-radius: 16px 16px 16px 16px;
}

.bottom {
  width: 100%;
  bottom: 0;
  left: 0;
  height: 151px;
  background: #ffffff;
  justify-content: center;
  border-top: 1px solid #c3c3c3;
}

.bottom-add {
  width: 670px;
  height: 80px;
  background: #0558f4;
  border-radius: 16px 16px 16px 16px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
  margin-top: 15px;
}

.bottom-btn {
  width: 325px;
  height: 80px;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  border: 2px solid #0558f4;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #0558f4;
  margin: 15px 0 0 0;
}

.bottom-btn_save {
  width: 325px;
  margin: 15px 0 0 20px;
  height: 80px;
  background: #0558f4;
  border-radius: 16px 16px 16px 16px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
}

.remind-check {
  width: 670px;
  padding: 52px 0 88px 0;
  margin: 0 auto;
  border-bottom: 1px solid #ededf7;
}

.remind-check_item {
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #101f48;
  background: #f1f3ff;
  border-radius: 4px 4px 4px 4px;
  padding: 7px 39px;
  margin:0 17px 17px 0;
}

.remind-check_selectItem {
  color: #0558f4;
  border: 1px solid #0558f4;
  background: #e8f0fe;
}
</style>
